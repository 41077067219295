import { fromJS } from 'immutable';

import { ACTIONS } from './actions';

// The initial state of the App
const initialState = fromJS({
  promoterCompanyBankAccountId: null,
  promoterBankAccountId: null,
  client: null,
  promoter: null,
});

function SharedStoreReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.PROMOTER_COMPANY_BANK_ACCOUNT_ID:
      return state.set('promoterCompanyBankAccountId', action.bankAccountId);
    case ACTIONS.PROMOTER_BANK_ACCOUNT_ID:
      return state.set('promoterBankAccountId', action.bankAccountId);
    case ACTIONS.SET_CLIENT:
      localStorage.setItem('ClientDocument', action.client.user.documentNumber);
      localStorage.setItem('isEmployee', action.client.isEmployee);
      return state.set('client', action.client);

    case ACTIONS.REMOVE_CLIENT:
      return state.set('client', null);
    case ACTIONS.SET_PROMOTER:
      return state.set('promoter', action.promoter);
    default:
      return state;
  }
}

export default SharedStoreReducer;
