import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import HelpIcon from '../svg/helpIcon';
import ProgressCircle from './progress-circle';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import Logo from '../../../../../images/brand/CorePartners/CorePartners_LogoRGB_positivo.png';
import { useHistory } from 'react-router-dom';
import getClientMode from 'src/store/utils-store/selectors';
import { useSelector } from 'react-redux';
import CorePartnersLogo from './svg/corePartners';
import getUserPercentageF2 from './services/getPercentage';
import { getClientSelector } from 'src/store/sharedStore/selectors';
const textCompletePercentageBanner = [
  {
    id: 3,
    title: ' ¡Bienvenido a Core Capital!',
    content:
      'Si has modificado tu información, haz clic en "Actualizar ficha" para que tus datos se mantengan correctos y al día.',
    action: '/cliente/ficha-cliente',
  },
];

const textPercentageBanner = [
  {
    id: 2,
    title: ' ¡Continúa tu Ficha Cliente!',
    content:
      'Queremos asegurarnos de que tengas la mejor experiencia posible. Por favor, dedica unos minutos a completar tu Ficha Cliente',
    action: '/cliente/ficha-cliente',
  },
];

const textWelcomeBanner = [
  {
    id: 1,
    title: '¡Bienvenido a Core Capital! ',
    content:
      'Queremos asegurarnos de que tengas la mejor experiencia posible. Por favor, dedica unos minutos a completar tu Ficha Cliente',
    action: '/cliente/ficha-cliente',
  },
];

function Banners(props) {
  const client = useSelector(getClientSelector());
  const { urlF2 } = props;
  const classes = useStyles();
  const modeClient = useSelector(getClientMode());
  const [percentage, setPercentage] = useState(0);
  const history = useHistory();
  const contentBannerStyleWithIcon = modeClient
    ? classes.contentBannersDarkIcon
    : classes.contentBannersIcon;
  const contentBannerStyleNoIcon = modeClient
    ? classes.contentBannersDark
    : classes.contentBanners;
  const bannerIcon =
    percentage > 0 && percentage < 100
      ? contentBannerStyleNoIcon
      : contentBannerStyleWithIcon;

  useEffect(() => {
    const documentNumber = localStorage.getItem('ClientDocument');

    const getUserPercentage = async () => {
      try {
        const response = await getUserPercentageF2(documentNumber);
        if (response) {
          setPercentage(response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getUserPercentage();
  }, [client]);

  return (
    <>
      <div className={classes.bannerDiv}>
        <div className={bannerIcon}>
          <div className={classes.mainDiv}>
            {percentage > 0 && percentage < 100 && (
              <>
                {textPercentageBanner.map(item => {
                  return (
                    <>
                      <div className={classes.divProgress}>
                        <div className={classes.devTextProgress}>
                          <p className={classes.title}>{item.title}</p>
                          <p className={classes.content}>{item.content}</p>
                          <div className={classes.btnDiv}>
                            <div
                              role="button"
                              onClick={() => history.push(item.action)}
                              tabIndex={0}
                              className={classes.btn}
                              onKeyDown={e => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                  history.push(item.action);
                                }
                              }}
                            >
                              Continuar Ficha
                            </div>
                          </div>
                        </div>

                        <ProgressCircle percentage={percentage} />
                      </div>
                    </>
                  );
                })}
              </>
            )}
            {percentage == 100 && (
              <>
                {textCompletePercentageBanner.map(item => {
                  return (
                    <>
                      <div className={classes.bannerDivText}>
                        <p className={classes.title}>{item.title}</p>
                        <p className={classes.content}>{item.content}</p>
                        <div className={classes.btnDiv}>
                          <div
                            role="button"
                            onClick={() => {
                              window.location.href = urlF2;
                            }}
                            tabIndex={0}
                            className={classes.btn}
                            onKeyDown={e => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                window.location.href = urlF2;
                              }
                            }}
                            className={classes.btn}
                          >
                            Actualizar Ficha
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
            {percentage == 0 && (
              <>
                {textWelcomeBanner.map(item => {
                  return (
                    <>
                      <div className={classes.bannerDivText}>
                        <p className={classes.title}>{item.title}</p>
                        <p className={classes.content}>{item.content}</p>
                        <div className={classes.btnDiv}>
                          <div
                            role="button"
                            onClick={() => {
                              window.location.href = urlF2;
                            }}
                            tabIndex={0}
                            className={classes.btn}
                            onKeyDown={e => {
                              if (e.key === 'Enter' || e.key === ' ') {
                                window.location.href = urlF2;
                              }
                            }}
                            className={classes.btn}
                          >
                            Completar Ficha
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Banners;
